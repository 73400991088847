export const DefaultPermission = [
    {
        "per_name": "General",
        "per_id": "4190b07a-103b-420a-ac50-44d1ae252aad",
        "_id": "6583dd58579310b723911715"
    },
    {
        "per_name": "Expert",
        "per_id": "a1a915e4-afea-4b9f-a9d6-7a745dfc701a",
        "admnrl_per_view": true,
        "admnrl_per_edit": true,
        "admnrl_per_export": true,
        "admnrl_per_delete": true,
        "admnrl_per_create": true,
        "admnrl_per_active": true,
        "_id": "65857b1b4cec4496f8179c7f"
    },
    {
        "per_name": "Appointment",
        "per_id": "37cf2eec-4283-496e-a608-21ab070a831d",
        "admnrl_per_view": true,
        "admnrl_per_edit": true,
        "admnrl_per_export": true,
        "admnrl_per_delete": true,
        "admnrl_per_create": true,
        "admnrl_per_active": true,
        "_id": "658a6374548c96d270087088"
    },
    {
        "per_name": "Patient",
        "per_id": "1b7a030d-baa7-4ee0-aed3-0ce3b8ae8d8d",
        "admnrl_per_view": true,
        "admnrl_per_export": true,
        "admnrl_per_active": true,
        "_id": "65897139dc603f1facee0c01"
    },
    {
        "per_name": "Account",
        "per_id": "37cf2eec-4283-496e-a608-21ab070a831d",
        "admnrl_per_view": true,
        "admnrl_per_edit": true,
        "_id": "658a6374548c96d270087088"
    },
    {
        "per_name": "Notification",
        "per_id": "37cf2eec-4283-496e-a608-21ab070a831d",
        "admnrl_per_view": true,
        "admnrl_per_edit": true,
        "_id": "658a6374548c96d270087088"
    },
]