// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  apiUrl: 'api',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration:
    'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl:
    'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl:
    'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  apis: {
    user: 'https://dev-api.gytree.com/api/v1/doctor',
    google: 'https://www.googleapis.com/customsearch/v1',
    ipfy: 'https://api.ipify.org'
  },
  email: 'support@gytree.com',
  currencySymbol: '₹',
  imageCompression: {
    ratio: 70,
    quality: 65
  },
  media_validator: {
    thumbnail: { validators: { required: false, allowedExtensions: ['jpeg', 'webp', 'avif', 'jpg', 'png'], maxFileSize: 5 } },
    audio: { validators: { required: true, allowedExtensions: ['MP3', 'wav', 'm4a', 'wma', 'aiff'], maxFileSize: 200 } },
    video: { validators: { required: true, allowedExtensions: ['MP4'], maxFileSize: 550 } },
    image: { validators: { required: true, allowedExtensions: ['jpeg', 'webp', 'avif', 'jpg', 'png'], maxFileSize: 5 } },
    document: { validators: { required: true, allowedExtensions: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'pps', 'ppsx', 'odt', 'xls', 'xlsx', 'txt', 'rtf'], maxFileSize: 10 } },
    gallery: { validators: { required: true, allowedExtensions: ['jpeg', 'webp', 'avif', 'jpg', 'png', 'MP4'], maxFileSize: 200 } },
  },
  type_validator: {
    image: { validators: { required: false, allowedExtensions: ['jpeg', 'webp', 'avif', 'jpg', 'png'], maxFileSize: 5 } },
    commonmedia: { validators: { required: false, allowedExtensions: ['jpeg', 'webp', 'avif', 'jpg', 'png', 'MP3', 'wav', 'm4a', 'wma', 'aiff', 'MP4', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'pps', 'ppsx', 'odt', 'xls', 'xlsx', 'txt', 'rtf'], maxFileSize: 100 } },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
