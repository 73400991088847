/**
 * This util is for get and set token and user related data
 * For get & set user and token related stuff.
 *
 * @author Uchit <uchit@tatvic.com>
 *
 * Notes:-
 * Date: 02/10/2020 (Uchit <uchit@tatvic.com>) initially created token storage util
 */
export class TokenUtil {
  /**
    * Set access token
    * @param token {string} : token used for store in local storage
    * @returns TokenStorage
    */
  public static setAccessToken(token: string): void {
    localStorage.setItem(btoa('accessToken'), token);
  }

  /**
  * Get Access Token
  * @returns token {string}
  */
  public static getAccessToken(): string | null {
    const token: string | null = localStorage.getItem(btoa('accessToken'));
    return token;
  }

  /**
    * Get Admin Id
    * @returns userId {string}
    */
  public static getAdminId(): string {
    const uniqueId: string = localStorage.getItem(btoa('adminId')) as string;
    if (!!(uniqueId && !uniqueId.includes('undefined') && !uniqueId.includes('null'))) {
      return uniqueId;
    }
    return undefined;
  }

  /**
    * Set Business Id
    * @returns TokenStorage
    */
  public static setAdminId(uniqueId: string): void {
    localStorage.setItem(btoa('adminId'), uniqueId);
  }

  /**
    * Validate Access Token
    * @returns token {string}
    */
  public static validateAccessToken(): boolean {
    const token: string | null = this.getAccessToken();
    return !!(token && !token.includes('undefined') && !token.includes('null'));
  }

  /**
    * Clear LocalStorage
    * @returns void
    */
  public static clear() {
    localStorage.clear();
  }

  /**
 * Get Permission
 * @returns token {string}
 */
  public static getPermission(): boolean {
    const permission: string | null = localStorage.getItem('permission');
    return !!(permission && !permission.includes('undefined') && !permission.includes('null'));
  }

  /**
  * Set Ip Adrress
  * @returns void
  */
  public static setIpAddress(ip) {
    return sessionStorage.setItem(btoa('user-ip'), ip);
  }

  /**
  * Get Ip Adrress
  * @returns ip {string}
  */
  public static getIpAddress(): string {
    return sessionStorage.getItem(btoa('user-ip'));
  }
}
