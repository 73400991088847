// ANGULAR DEPENDANCY
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// EXTERNAL DEPENDANCY
import { Observable, catchError, map, of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

// Services
import { AdminService } from 'src/app/api/services/admin/admin.service';
import { AdminStorageService } from 'src/app/core/storage/admin-storage.service';

// Enums & Interfaces
import { ApiAction } from '../../constants/models/api';

// Utils
import { TokenUtil } from '../utils/token';
import { ApiUtil } from '../utils/api';
import { PermissionUtil } from '../utils/permission/permission.util';

// Constants
import { ADMIN_API } from 'src/app/api/constants/setting/admin';

/**
 * This component used to verify access token if exists navigate to default page
 * @author Uchit <uchit@tatvic.com>
 *
 * Notes:-
 * Date: 29/04/2021 (Uchit <uchit@tatvic.com>) Signin guard created
 */
@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private adminService: AdminService,
    private storage: AdminStorageService,
    private permission: NgxPermissionsService,
  ) { }

  /**
   * Checks if the ActivatedComponent can be activated or not based on the token
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkIfAuthorized(route, state);
  }

  /**
   * Checks if the Activated component is authorized
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  checkIfAuthorized(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    let admin: any = this.storage.getAdmin();
    if (admin && admin !== 'null') {
      this.setPermission(admin?.access?.admnrl_permission);
      return of(true);
    }

    const info: ApiAction = ApiUtil.configureGet({ url: ADMIN_API.ADMIN_GET });

    return this.adminService.adminCommon(info, { dr_id: TokenUtil.getAdminId() }).pipe(map(res => {
      this.setPermission(res?.data?.access?.admnrl_permission);
      this.storage.setAdmin(res?.data);
      return true;
    }), catchError(err => {
      this.router.navigate(['error']);
      return of(false);
    }));
  }

  setPermission(permissions) {
    this.permission.flushPermissions();
    this.permission.loadPermissions(PermissionUtil.setPermission(permissions));
  }
}
